import { Button, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Article } from '../../../global';
import { getFeedbackList, updateFeedbackById } from '../../../setup/api/feedback';
import { getUsersForStatistics } from '../../../setup/api/user';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';
import { ComparLightChip } from '../../../ui/components/TagChips/ComparLightChip';
import { TitleHeading } from '../../../ui/components/Titles/Title';
import { comparColors } from '../../../ui/helpers/colors';
import { UserModel } from '../auth/interfaces';

type Heading = [string, string];
/**
 *  Check the user role and loads different dashboards depending on it
 */

type CountryStatistic = {
    country: string;
    count: number;
};

export const ShowUserStatisticsPage: FC = () => {
    const { request, isLoading, abortController } = useFetch();

    const [userCount, setUserCount] = useState(0);
    const [userHairProfiles, setUserHairProfiles] = useState(0);
    const [unconfirmedUsers, setUnconfirmedUsers] = useState(0);
    const [adminUserCount, setAdminUserCount] = useState(0);
    const [userList, setUserList] = useState();
    const [userFeedbackCount, setUserFeedbackCount] = useState(0);
    const [userFeedbackList, setUserFeedbackList] = useState();
    const [userCountryStatistics, setUserCountryStatistics] = useState<CountryStatistic[]>([]);
    const [showUserCountryGrid, setShowUserCountryGrid] = useState(false);

    const update = useFetchWithAlert(alertPayload(`Feedback Updated`));

    const [showHandled, setShowHandled] = useState<boolean>(false);

    useEffect(() => {
        request(getUsersForStatistics, abortController.signal)
            .then(result => {
                if (result) {
                    setUserCount(result.userCount);
                    setUserList(result.users);
                    setUnconfirmedUsers(result.unconfirmedUserCount);
                    const hairProfileUsers = result.users.filter((user: any) => user.profile);
                    const adminUsers = result.users.filter((user: UserModel) => user.userRole === `admin`);

                    setUserHairProfiles(hairProfileUsers.length);
                    setAdminUserCount(adminUsers.length);

                    // Count users by country
                    const countryCount = result.users.reduce((acc: Record<string, number>, user: any) => {
                        if (user.country) {
                            acc[user.country] = (acc[user.country] || 0) + 1;
                        }
                        return acc;
                    }, {} as Record<string, number>);

                    // Convert the object into an array of objects and sort by count
                    const sortedCountries: CountryStatistic[] = Object.entries(countryCount)
                        .map(([country, count]) => ({ country, count: count as number }))
                        .sort((a, b) => b.count - a.count);

                    // Set the sorted list of countries in the state
                    setUserCountryStatistics(sortedCountries);
                }
            });
    }, []);

    useEffect(() => {
        const query = `?showHandled=${showHandled}`;

        request(getFeedbackList, query, abortController.signal).then(feedback => {
            if (feedback) {
                // Map feedback results to include user email
                const feedbackWithUserEmail = feedback.results.map((feedbackItem: any) => ({
                    ...feedbackItem,
                    userEmail: feedbackItem.user.email,
                }));

                setUserFeedbackList(feedbackWithUserEmail); // Update userFeedbackList state
                setUserFeedbackCount(feedback.totalPages * 10);
            }
        });
    }, [showHandled]);

    const handleReadChange = async (feedbackId: string, currentStatus: boolean) => {
        const payload = { read: !currentStatus };

        update.request(updateFeedbackById, feedbackId, payload, abortController.signal)
            .then(res => {
                console.log(res);
                // Assuming `res` contains the updated article or relevant data
                setUserFeedbackList((prevUsers: any | undefined) => {
                    if (!prevUsers) {
                        return [];
                    }

                    return prevUsers.map((user: { id: any; }) => {
                        if (user.id === feedbackId) {
                            return { ...user, read: !currentStatus };
                        }
                        return user;
                    });
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    return <Grid>
        <table width={`100%`} style={{ marginBottom: 50 }}>
            <thead>
                <th><TitleHeading children="Amount of users" style={{ fontSize: `1.25em` }} /></th>
                <th><TitleHeading children="Users with hair profile" style={{ fontSize: `1.25em` }} /></th>
                <th><TitleHeading children="Unconfirmed users" style={{ fontSize: `1.25em` }} /></th>
                <th><TitleHeading children="Admin users" style={{ fontSize: `1.25em` }} /></th>
            </thead>
            <tbody>
                <td><TitleHeading children={userCount} style={{ fontSize: `1.1em` }} /></td>
                <td><TitleHeading children={userHairProfiles} style={{ fontSize: `1.1em` }} /></td>
                <td><TitleHeading children={unconfirmedUsers} style={{ fontSize: `1.1em` }} /></td>
                <td><TitleHeading children={adminUserCount} style={{ fontSize: `1.1em` }} /></td>
            </tbody>
        </table>
        <div style={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            marginTop: `1.2em`,
            width: `17.5%`
        }}>
            <Button
                type="button"
                variant="contained"
                component="a"
                href="https://analytics.google.com/analytics/web/?authuser=0&hl=en#/p439735776/reports/reportinghub?params=_u..nav%3Dmaui"
                target="_blank"
                rel="noopener noreferrer"
            >
                General Analytics
            </Button>
            <Button
                type="button"
                variant="contained"
                component="a"
                href="https://analytics.google.com/analytics/web/?authuser=0&hl=en#/p439735776/reports/explorer?r=8162089678"
                target="_blank"
                rel="noopener noreferrer"
            >
                Purchasing insights
            </Button>
        </div>
        <div>
            <div style={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
                marginTop: `1.2em`,
                width: `17.5%`
            }}>
                <Button
                    type="button"
                    variant="contained"
                    component="a"
                    rel="noopener noreferrer"
                    onClick={() => setShowUserCountryGrid(!showUserCountryGrid)}
                >
                    {showUserCountryGrid ? `Hide user country statistics` : `Show user country statistics`}
                </Button>
            </div>

            {/* Conditionally render the Grid */}
            {showUserCountryGrid && (
                <Grid container spacing={1} style={{ display: `flex`, flexWrap: `wrap` }}>
                    {
                        userCountryStatistics.length > 0
                            ? (
                                userCountryStatistics.map((userCountry, index) => (
                                    <Grid item xs={3} key={index} style={{ marginRight: `8px`, marginBottom: `8px` }}>
                                        <ComparLightChip
                                            style={{ fontSize: `13px` }}
                                            label={`${userCountry.country}: ${userCountry.count}`}
                                            variant="filled"
                                        />
                                    </Grid>
                                )))
                            :
                            (
                                <Grid style={{ marginTop: `8px`, marginLeft: `8px` }}>
                                    <TitleHeading children={`Sending to all countries, as no specific is selected`} style={{
                                        fontSize: `0.75rem`,
                                        color: comparColors.primary.main,
                                        textAlign: `center`
                                    }} />
                                </Grid>
                            )
                    }
                </Grid>
            )}
        </div>
        <Grid xs={12} marginTop={3}>
            <FilterComponent
                count={userFeedbackCount}
                entity='feedback'
                // showSearchFilter
                showPagination
            />
        </Grid>
        <Grid container justifyContent={`flex-start`} alignItems={`center`} >
            <Checkbox
                checked={showHandled}
                onChange={() => setShowHandled(!showHandled)}
                inputProps={{ 'aria-label': `controlled` }}
            />
            <TitleHeading children="Show handled feedback" style={{
                fontSize: `1rem`
            }} />
        </Grid>
        {
            userFeedbackList && <Datagrid<Article>
                data={userFeedbackList}
                headings={userHeadings}
                loading={isLoading}
                renderRow={(headings: Heading[], item: Partial<Record<string, unknown>>) => renderRows(headings, item, handleReadChange)}
                link='feedback'
            />
        }
    </Grid >;
};

export const userHeadings: Array<[string, string]> = [
    [`feedback`, `Feedback`],
    [`userEmail`, `Email`],
    [`platform`, `Device platform`],
    [`createdAt`, `Created`],
    [`read`, `Handled?`]
];

function renderRows(
    headings: Heading[],
    item: Partial<Record<string, unknown>>,
    handleReadChange: (feedbackId: string, currentStatus: boolean) => void
) {
    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: `numeric`,
            month: `long`,
            day: `numeric`,
            hour: `numeric`,
            minute: `numeric`,
        };

        return new Date(dateString).toLocaleString(undefined, options);
    };

    return headings.map(([key]) => {
        if (key === `createdAt` || key === `updatedAt`) {
            return <td key={key}>{formatDate(item[key] as string)}</td>;
        }
        if (key === `read`) {
            return (
                <td key={key}>
                    <Checkbox
                        checked={item[key] as boolean}
                        onChange={() => handleReadChange(item.id as string, item[key] as boolean)}
                        color="primary"
                    />
                </td>
            );
        }
        return <td key={key}>{(item[key] as string)}</td>;
    });
}