import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";

import { comparColors } from "../../helpers/colors";

export const ComparTextField = styled(TextField)(({ color }) => ({
    minWidth: `10em`,
    backgroundColor: comparColors.primary.light,
    "& .MuiOutlinedInput-root": {
        "& legend": {
            maxWidth: `0px`,
            padding: `0px`,
        },
        "& .MuiInputLabel-root": {
            fontFamily: `ProximaNova-Semibold`,
            fontSize: `14px`,
            fontWeight: `normal`,
            lineHeight: `16px`,
        },
        "& fieldset": {
            borderRadius: `20px`,
            border: `none`,
            minWidth: `100%`,
            maxWidth: `100%`,
            fontFamily: `ProximaNova-Regular`,
            boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.10)`, // Add the box shadow
        },
    },
    ...(color === `secondary` && {
        backgroundColor: comparColors.white,
    })
}));